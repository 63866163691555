<template>
	<b-card>
		<b-row class="m-1 top-header">
			<b-col md="6" class="d-flex align-items-center cursor-pointer">
				<div @click="goBack">
					<feather-icon icon="ArrowLeftIcon" size="24" />
				</div>
				<h3 class="ml-2 mb-0">{{ hatch_id }}</h3>
			</b-col>
		</b-row>
		<div v-for="item in items" :key="item.message"></div>
		<b-row class="justify-content-end">
			<span class="mr-1"> System</span>

			<b-form-checkbox
				v-model="typeMachine"
				checked="true"
				value="System"
				unchecked-value="manual"
				name="typeMachine"
				switch
				inline
			>
				<span class="mr-1"> Manual</span>
			</b-form-checkbox>
		</b-row>
		<b-row>
			<b-col md="6">
				<b-card no-body>
					<b-card-header>
						<div>
							<b-card-title> Temperature </b-card-title>
						</div>

						<!--/ badge -->
					</b-card-header>
					<b-card-body>
						<vue-apex-charts
							id="revenue-report-chart"
							type="line"
							height="230"
							:options="temperature.chartOptions"
							:series="temperature.series"
						/>
					</b-card-body>
				</b-card>
			</b-col>
			<b-col md="6">
				<b-card no-body>
					<b-card-header>
						<!-- title and subtitle -->
						<div>
							<b-card-title> Humidity </b-card-title>
						</div>

						<!--/ badge -->
					</b-card-header>
					<!-- {{ revenueReport.series.data}} -->
					<b-card-body>
						<vue-apex-charts
							id="revenue-report-chart"
							type="line"
							height="230"
							:options="humidity.chartOptions"
							:series="humidity.series"
						/>
					</b-card-body>
				</b-card>
			</b-col>
		</b-row>
	</b-card>
	<!-- <b-card v-if="data" no-body class="card-revenue-budget">
    <b-row class="mx-0">
      <b-col md="8" class="revenue-report-wrapper">
     
        <vue-apex-charts
          id="revenue-report-chart"
          type="bar"
          height="230"
          :options="revenueReport.chartOptions"
          :series="data.revenueReport.series.data"
        />
      </b-col>
    </b-row>
  </b-card> -->
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BDropdown,
	BDropdownItem,
	BButton,
	BFormCheckbox,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import Ripple from "vue-ripple-directive";

export default {
	components: {
		VueApexCharts,
		BDropdown,
		BDropdownItem,
		BCard,
		BButton,
		BRow,
		BCol,
		BFormCheckbox,
	},
	directives: {
		Ripple,
	},
	props: [
		"hatchery_device_id",
		// data: {
		//   type: Array,
		//   default: () => {},
		// },
	],
	data() {
		return {
			typeMachine: "System",
			humidityData: [],
			temperature: {
				series: [
					{
						data: [
							0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
						],
					},
				],
				chartOptions: {
					chart: {
						zoom: {
							enabled: false,
						},
						toolbar: {
							show: false,
						},
					},
					markers: {
						strokeWidth: 7,
						strokeOpacity: 1,
						strokeColors: [$themeColors.light],
						colors: [$themeColors.warning],
					},
					colors: [$themeColors.warning],
					dataLabels: {
						enabled: false,
					},
					stroke: {
						curve: "straight",
					},
					grid: {
						xaxis: {
							lines: {
								show: true,
							},
						},
					},
					tooltip: {
						custom(data) {
							return `${'<div class="px-1 py-50"><span>'}${
								data.series[data.seriesIndex][data.dataPointIndex]
							}%</span></div>`;
						},
					},
					xaxis: {
						categories: [
							"0",
							"1",
							"2",
							"3",
							"4",
							"5",
							"6",
							"7",
							"8",
							"9",
							"10",
							"11",
							"12",
							"13",
							"14",
							"15",
							"16",
							"17",
							"18",
							"19",
							"20",
							"21",
						],
					},
					yaxis: {
						// opposite: isRtl,
					},
				},
			},
			humidity: {
				series: [
					{
						data: [
							0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
						],
					},
				],
				chartOptions: {
					chart: {
						zoom: {
							enabled: false,
						},
						toolbar: {
							show: false,
						},
					},
					markers: {
						strokeWidth: 7,
						strokeOpacity: 1,
						strokeColors: [$themeColors.light],
						colors: [$themeColors.warning],
					},
					colors: [$themeColors.warning],
					dataLabels: {
						enabled: false,
					},
					stroke: {
						curve: "straight",
					},
					grid: {
						xaxis: {
							lines: {
								show: true,
							},
						},
					},
					tooltip: {
						custom(data) {
							return `${'<div class="px-1 py-50"><span>'}${
								data.series[data.seriesIndex][data.dataPointIndex]
							}%</span></div>`;
						},
					},
					xaxis: {
						categories: [
							"0",
							"1",
							"2",
							"3",
							"4",
							"5",
							"6",
							"7",
							"8",
							"9",
							"10",
							"11",
							"12",
							"13",
							"14",
							"15",
							"16",
							"17",
							"18",
							"19",
							"20",
							"21",
						],
					},
					yaxis: {
						// opposite: isRtl,
					},
				},
			},
		};
	},
	created() {
		const machineId = this.$route.params.machineId;
		const hatch_id = this.$route.params.hatchId;
		this.hatch_id = hatch_id;
		this.$http
			.get(
				`web/get-temperature-graph/${hatch_id}/device/${this.hatchery_device_id}/${this.typeMachine}/day`
			)
			.then((response) => {
				let data = response.data.deviceInfo.map((item) => {
					return item.temp_celsius;
				});
				// humidity
				let humidityData = response.data.deviceInfo.map((item) => {
					return item.humidity;
				});

				if (data.length < 21) {
					data = data.concat(Array(21 - data.length).fill(0));
				}

				if (humidityData.length < 21) {
					humidityData = humidityData.concat(
						Array(21 - humidityData.length).fill(0)
					);
				}
				this.temperature.series = [
					{
						data: data,
					},
				];

				this.humidity.series = [
					{
						data: humidityData,
					},
				];
			});
	},
	methods: {
		goBack() {
			this.$router.go(-1);
		},
		getGraphData() {
			const hatch_id = this.$route.params.hatchId;
			this.hatch_id = hatch_id;
		},
	},
};
</script>
