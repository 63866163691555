<template>
	<b-card>
		<ecommerce-revenue-report
			:data="data.deviceInfo"
			:hatchery_device_id="hatchery_device_id"
		/>
	</b-card>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import { getUserData } from "@/auth/utils";
import axiosIns from "@/libs/axios";
import EcommerceRevenueReport from "./EcommerceRevenueReport.vue";

export default {
	components: {
		BRow,
		BCol,
		EcommerceRevenueReport,
	},
	data() {
		return {
			data: {},
			hatch_id: null,
			hatchery_device_id: null,
		};
	},
	created() {
		const hatch_id = this.$route.params.hatchId;

		this.hatchery_device_id = this.$route.params.hatchery_device_id;
		this.hatch_id = hatch_id;
		this.$http
			.get(
				`web/get-temperature-graph/${hatch_id}/device/${this.hatchery_device_id}/live/day`
			)
			.then((response) => {
				this.data = response.data;
			});
	},
	methods: {},
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";
</style>
